import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {
	Grid,
	Typography
} from '@material-ui/core';
import {Style} from '@material-ui/icons';
import TextInput from '../../common/Components/TextInput';
import ProduitsOriginesCss from './css/ProduitsOriginesCss';
import CollectionCrud from '../../common/Components/CollectionCrud';
import CheckboxInput from '../../common/Components/CheckboxInput';

const REF_PREFIX = {
  ENTAME: 'E',
  SILO: 'S',
  SILO_INCOMPLET: 'SE',
}

class ProduitsOrigines extends Component {
	/**
	 * Title de la modal de suppression
	 * @return {[type]} [description]
	 */
	getDeleteModalTitle() {
		return <div><Style/> <span>{'Suppression de l\'origine'}</span></div>;
	}

	getDeleteModalContent() {
		return <Typography>{'Êtes-vous sûr de vouloir supprimer cette origine ?'}</Typography>;
	}

	getCreateUpdateModalTitle(origine) {
		return <div>
			<Style/> <span>{!!origine ? 'Modification de l\'origine : ' + origine.libelle : "Ajout d'une nouvelle origine"}</span>
		</div>;
	}

	/**
	 * Retourne le formulaire
	 * @returns {*}
	 */
	getCreateUpdateModalContent(origine, produitsOriginesStore, onChangeHandler) {
		let {libelle, code, can_be_imported, groupe_filtre, autoincrement, create_unactivated, zone_bb_uuid} = origine

		const {classes} = this.props;

		return <Grid container>
			<Grid item xs={12}>
				<TextInput
					id="code"
					label="Code"
					value={code}
					margin="normal"
					collectionStore={produitsOriginesStore}
					name='code'
					onChangeHandler={onChangeHandler}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12}>
				<TextInput
					id="libelle"
					label="Libellé"
					value={libelle}
					margin="normal"
					collectionStore={produitsOriginesStore}
					name='libelle'
					onChangeHandler={onChangeHandler}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12}>
				<TextInput
					id="zone_bb_uuid"
					label="Zone BB Sortie"
					className={classes.selectContainer}
					value={zone_bb_uuid}
					margin="normal"
					collectionStore={produitsOriginesStore}
					onChangeHandler={onChangeHandler}
					name="zone_bb_uuid"
					type="autocomplete"
					autocompleteProps={{
					collectionName: "zone_bbs",
					}}
					fullWidth
              	/>
			{/* zone_bb_uuid */}
			</Grid>
			<Grid item xs={12}>
				<TextInput
					id="groupe_filtre"
					label="Groupe filtre"
					value={groupe_filtre}
					margin="normal"
					collectionStore={produitsOriginesStore}
					name='groupe_filtre'
					onChangeHandler={onChangeHandler}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12}>
				<CheckboxInput
					id="can_be_imported"
					label="Importable en masse"
					value={can_be_imported}
					margin="normal"
					collectionStore={produitsOriginesStore}
					name='can_be_imported'
					onChangeHandler={onChangeHandler}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12}>
				<CheckboxInput
					id="autoincrement"
					label="Numéro auto-incrémenté"
					value={autoincrement}
					margin="normal"
					collectionStore={produitsOriginesStore}
					name='autoincrement'
					onChangeHandler={onChangeHandler}
					fullWidth
				/>
			</Grid>
			<Grid item xs={12}>
				<CheckboxInput
					id="create_unactivated"
					label="BB créé non activé"
					value={create_unactivated}
					margin="normal"
					collectionStore={produitsOriginesStore}
					name='create_unactivated'
					onChangeHandler={onChangeHandler}
					fullWidth
				/>
			</Grid>
		</Grid>
	}

	/**
	 * Fonction de rendu Final
	 * @return {[type]} [description]
	 */
	render() {
		const {classes} = this.props;
		return <Grid container className={classes.container}>
			<CollectionCrud
				collectionName={'produits_origines'}
				showBtnEdit={true}
				showBtnAdd={true}
				showBtnDelete={true}
				rights={{
					create: 'admin-cud-parametres',
					edit: 'admin-cud-parametres',
					delete: 'admin-cud-parametres'
				}}
				deleteModalTitle={this.getDeleteModalTitle}
				deleteModalContent={this.getDeleteModalContent}
				createUpdateModalTitle={this.getCreateUpdateModalTitle}
				createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
				datatableConfig={{
					showPagination: true,
					showSearch: true,
					defaultSort: "libelle",
					sortType: 'desc',
					rowsPerPageOptions: [10, 25, 50],
				}}
				cellsConfig={[
					{
						datakey: 'code',
						title: 'Code',
						sortable: true,
						searchable: true
					},
					{
						datakey: 'libelle',
						title: 'Libelle',
						sortable: true,
						searchable: true
					},
					{
						datakey: 'groupe_filtre',
						title: 'Groupes filtre',
						sortable: true,
						searchable: true
					},
					{
						datakey: 'can_be_imported',
						title: 'Importable',
						sortable: true,
						searchable: true,
						format: (obj,key) => {
							return obj[key] ? 'Oui' : 'Non'
						}
					},
					{
						datakey: 'autoincrement',
						title: 'Auto-incrémenté',
						sortable: true,
						searchable: true,
						format: (obj,key) => {
							return obj[key] ? 'Oui' : 'Non'
						}
					},
					{
						datakey: 'create_unactivated',
						title: 'BB Créé non activé',
						sortable: true,
						searchable: true,
						format: (obj,key) => {
							return obj[key] ? 'Oui' : 'Non'
						}
					},
					{
						datakey: 'zone_bb_uuid',
						title: 'Zone BB Sortie',
						sortable: true,
						searchable: true,
						format: (obj,key) => {
							return obj.zone_bb_sortie_name
						}
					}
				]}
			/>
		</Grid>
	}
}

ProduitsOrigines = withStyles(ProduitsOriginesCss)(ProduitsOrigines);

ProduitsOrigines = connect((store) => {
	return {
		produitsOriginesStore: store.collections.produits_origines,
	}
})(ProduitsOrigines);

export default ProduitsOrigines
export { REF_PREFIX, ProduitsOrigines }
