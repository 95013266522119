import React, { Component } from "react";

import ZonePlanCss from "./css/ZonePlanCss";
import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  withStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "../../common/Components/Modal";
import TextInput from "../../common/Components/TextInput";
import { collectionActions, errorHandlerCollectionCrud } from "../../../reducers/collectionsReducer";
import { axiosClient } from "../../../axios";
import { addNotification } from "../../../reducers/notificationReducer";
import { themeComplement } from "../AppAdmin/css/theme";
import { CloudDownload, Info, Warning } from "@material-ui/icons";
class ZonePlan extends Component {
  state = {
    currentCellule: null,
    openModalCellule: false,
    loading: false,
    keyword: "",
    openModalStackNearWall: false,
    selectedLetter: null,
    openModalInfoBulle: false,
    letters: []
  };

  componentDidMount() {
    const { cellules } = this.props;
    const letters = [];
    cellules.forEach((row) => {
      row.forEach((cellule) => {
        if(cellule.type === 'wall'){
          letters.push(cellule.letter);
        }
      });
    });

    this.setState({letters});
  }

  getCelluleContent(cellule) {
    return cellule.libelle;
  }

  displayCellule(cellule, index) {
    if (!cellule) return null;
    const { classes, wallPosition, editCelluleMode } = this.props;
    const style = {
      wordBreak: "break-all",
      lineHeight:1,
    };

    const is_editable = editCelluleMode && cellule.type !== "wall";

    if(cellule.color){
      style.backgroundColor = cellule.color;
    }

    if(cellule.box_shadow_color){
      style.boxShadow = `inset 0px 0px 0px 5px ${cellule.box_shadow_color}`;
    }

    Object.keys(cellule.border_style).forEach((key) => {
      if(cellule.border_style[key] === 'none'){
        style['border'+key] = 'none';
      }
    });

    let classesName = [classes.cellule];
    if (cellule.is_stockable || is_editable) {
      classesName.push("stockable");
    }

    if (
      cellule.type === "wall" &&
      (wallPosition === "left" || wallPosition === "right")
    ) {
      classesName.push(classes.wall);
      if (wallPosition === "left") classesName.push(classes.stickyLeft);
      if (wallPosition === "right") classesName.push(classes.stickyRight);
    }

    const onClickHandler =
      (cellule.is_stockable || is_editable)
        ? this.onClickCelluleHandler.bind(this, cellule)
        : null;
    return (
      <td
        key={index}
        className={classesName.join(" ")}
        style={style}
        onClick={onClickHandler}
      >
        {this.getWarning(cellule)}
        {this.getCelluleContent(cellule)}
      </td>
    );
  }

  getWarning(cellule) {
    if (!cellule.bb_short?.warning) return null;
    const { classes } = this.props;
    return (
      <div className={classes.warning_cell}>
        <Warning
          style={{
            fontSize: "2rem",
          }}
        />
      </div>
    );
  }

  onClickCelluleHandler(cellule) {
    const { big_bag_deplacement } = this.props;
    if (big_bag_deplacement) {
      if (cellule.is_stockable && !cellule.produit_transforme_uuid) {
        this.callApi(
          {
            ...cellule,
            produit_transforme_uuid:big_bag_deplacement.produit_transforme_uuid,
            force: true,
          },
          () => {
            this.props.initDeplacementBigBag(null);
          }
        );
      }
    } else {
      this.setState({
        currentCellule: cellule,
        openModalCellule: true,
      });
    }
  }

  displayRow(row) {
    return row.map((cellule, index) => {
      return this.displayCellule(cellule, index);
    });
  }

  displayZonePlan() {
    const { cellules, classes, wallPosition } = this.props;

    return (
      <table className={classes.tableZone}>
        <tbody>
          {cellules.map((row, index) => {
            const classelist = [classes.row];
            const firstcell = row[0];
            if (firstcell && firstcell.type === "wall") {
              classelist.push(classes.wall);
              if (wallPosition === "top") classelist.push(classes.stickyTop);
              if (wallPosition === "bottom")
                classelist.push(classes.stickyBottom);
            }
            return (
              <tr key={index} className={classelist.join(" ")}>
                {this.displayRow(row)}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  getCelluleTypeOptions() {
    const positions = [
      { value: "stockable", label: "Stockable" },
      { value: "stockable_additionnal", label: "Stockable additionnel" },
      { value: "no_stockable", label: "Non stockable" },
    ];

    return positions.map((position) => {
      return (
        <MenuItem value={position.value} key={position.value}>
          {position.label}
        </MenuItem>
      );
    });
  }

  getModalCellule() {
    const { editCelluleMode, classes, celluleStore } = this.props;
    const { currentCellule } = this.state;
    const inputs = [];

    if (!currentCellule) return null;

    const { type, produit_transforme_uuid } = currentCellule;
    if (editCelluleMode) {
      inputs.push(
        <TextInput
          id="type"
          label="Type"
          className={classes.selectContainer}
          value={type}
          margin="normal"
          collectionStore={celluleStore}
          name="type"
          onChangeHandler={this.onChangeCurrentCelluleHandler.bind(this)}
          fullWidth
          select
        >
          {this.getCelluleTypeOptions()}
        </TextInput>
      );
    }
    if (!editCelluleMode && currentCellule.is_stockable) {
      inputs.push(
        <TextInput
          id="produit_transforme_uuid"
          label="Big bag"
          className={classes.selectContainer}
          value={produit_transforme_uuid}
          margin="normal"
          collectionStore={celluleStore}
          name="produit_transforme_uuid"
          type="autocomplete"
          onChangeHandler={this.onChangeCurrentCelluleHandler.bind(this)}
          autocompleteProps={{
            collectionName: "produits_transformes",
          }}
          onChangeHandlerKeyword={(keyword) => {
            this.setState({ keyword });
          }}
          fullWidth
          clearable={true}
        />
      );
    }
    return [
      <DialogTitle key="title">
        <Typography>
          Cellule :{" "}
          {currentCellule && currentCellule.code ? currentCellule.code : null}
        </Typography>
        {
          currentCellule.bb_short && currentCellule.bb_short.info_bulle &&
          <IconButton
              className={classes.buttonInfo}
              size="small"
              onClick={() => {
                this.setState({openModalInfoBulle: true});
              }}
            >
              <Info fontSize="inherit" />
          </IconButton>
        }
      </DialogTitle>,
      <DialogContent key="content">{inputs}</DialogContent>,
    ];
  }

  onChangeCurrentCelluleHandler(name, e) {
    const { currentCellule } = this.state;
    currentCellule[name] = e.target.value;
    currentCellule.editing =
      currentCellule.editing || name === "produit_transforme_uuid";
    this.setState({ currentCellule });
  }

  onSubmitCelluleHandler() {
    const { currentCellule } = this.state;
    const { keyword } = this.state;
    if (keyword && !currentCellule.produit_transforme_uuid) {
      addNotification(this.props.dispatch, {
        message: "Veuillez sélectionner un big bag, ou vider le champ",
        bgColor: themeComplement.palette.notifications.error.color,
      });
    } else {
      this.callApi(currentCellule, () => {
        this.setState({
          openModalCellule: false,
          currentCellule: null,
          keyword: "",
        });
      });
    }
  }

  callApi(cellule, callback) {
    const { zoneBB_uuid, callbackUpdate } = this.props;
    this.setState({ loading: true });
    collectionActions(
      this.props.dispatch,
      "cellule-bbs",
      "UPDATE",
      {
        uuid: cellule.uuid,
        type: cellule.type,
        produit_transforme_uuid: cellule.produit_transforme_uuid,
        force: cellule.force || false,
      },
      (cellulle_api) => {
        this.setState({ loading: false });
        if (cellulle_api) {
          callback();
          collectionActions(
            this.props.dispatch,
            "zone-bbs",
            "SHOW_WITHOUT_PENDING",
            {
              uuid: zoneBB_uuid,
            }
          );
        }
        if (callbackUpdate) callbackUpdate();
      }
    );
  }

  initDeplacementBigBag() {
    const { currentCellule } = this.state;
    const { initDeplacementBigBag } = this.props;
    initDeplacementBigBag(currentCellule.bb_short);
    this.setState({ openModalCellule: false, currentCellule: null });
  }

  getAdditionalButtons() {
    const { currentCellule } = this.state;
    const { editCelluleMode } = this.props;
    if (editCelluleMode) return null;

    if (
      !currentCellule ||
      !currentCellule.produit_transforme_uuid ||
      currentCellule.editing
    )
      return null;
    return [
      {
        label: "Déplacer",
        color: "primary",
        action: this.initDeplacementBigBag.bind(this),
      },
    ];
  }

  getLettersOptions(){
    const { letters } = this.state;
    if(!letters) return [];

    return letters.map((letter) => {
      return (
        <MenuItem value={letter} key={letter}>
          {letter}
        </MenuItem>
      );
    });
  }

  getBtnStackNearWall() {
    const { editCelluleMode, wallPosition } = this.props;
    if (editCelluleMode || wallPosition === 'none') return null;

    return (
      <Button color="primary" onClick={() => {
        this.setState({openModalStackNearWall: true});
      }}>
        Empiler contre le mur
      </Button>
    );
  }

  getLoading() {
    const { loading } = this.state;
    if (!loading) return null;
    const { classes } = this.props;
    return (
      <div className={classes.loadingContainer}>
        <h4 className={classes.center}>Requête en cours auprès du serveur</h4>
        <CircularProgress />
      </div>
    );
  }

  getModalStackNearWall(){
    const { classes, celluleStore } = this.props;
    const { selectedLetter } = this.state;
    return (
      <Grid container>
      <Grid item xs={12}>
        <TextInput
          id="letter"
          label="Lettre du mur"
          className={classes.selectContainer}
          value={selectedLetter}
          margin="normal"
          name="letter"
          collectionStore={celluleStore}
          onChangeHandler={(name, e) => {
            this.setState({selectedLetter: e.target.value});
          }}
          fullWidth
          select
        >
          {this.getLettersOptions()}
        </TextInput>
      </Grid>
    </Grid>
    )
  }

  onSubmitStackNearWallHandler(){
    const { zoneBB_uuid, dispatch, callbackUpdate } = this.props;
    const { selectedLetter } = this.state;
    this.setState({ loading: true });
    axiosClient
      .post(`/zone-bbs/${zoneBB_uuid}/stack-near-wall`,{letter: selectedLetter})
      .then((response) => {
        dispatch({
          type: "DETAIL_FULFILLED",
          collection: "zone-bbs",
          payload: response.data,
        });
        this.setState({ loading: false, openModalStackNearWall: false });
        if (callbackUpdate) callbackUpdate();
      }).catch((error) => {
        errorHandlerCollectionCrud(error, dispatch,'cellule-bbs',() => {
          this.setState({ loading: false, openModalStackNearWall: false });
        });
      });
  }

  displayLegend(){
    const { legends, classes } = this.props;
    if(!legends) return null;
    return (
      <Grid container>
        <div className={classes.legendContainer}>
          {legends.map((legend) => {
            return (
              <div
                className={classes.legend}
                key={legend.label}
                style={{backgroundColor: legend.color}}
              >
                {legend.label}
              </div>
            );
          })}
        </div>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          {this.getBtnDownloadPlan()}
        </Grid>
      </Grid>
    )
  }

  getBtnDownloadPlan(){
    const { showButonDownload,getDownloadParameters, zoneBB_uuid } = this.props;
    if(!showButonDownload || !getDownloadParameters) return null;
    const parameters = getDownloadParameters();
    const url = new URL(process.env.REACT_APP_API_URL + "/zone-bbs/export");
    Object.keys(parameters).forEach(key => {
      if(parameters[key]){
        if(Array.isArray(parameters[key])){
          parameters[key].forEach((value) => {
            url.searchParams.append(key+'[]', value);
          });
        }else{
          url.searchParams.append(key, parameters[key]);
        }
      }
    });

    url.searchParams.append('current_zone', zoneBB_uuid);

    return (
      <Button
        color="primary"
        type="a"
        variant="contained"
        href={url}
        target="_blank"
      >
        <CloudDownload />
        Télecharger le plan
      </Button>
    )
  }


  getModalInfoBulle(){
    const { currentCellule } = this.state;
    if(!currentCellule) return null;
    const { bb_short } = currentCellule;
    if(!bb_short) return null;

    return [
      <DialogTitle key="title">
        <Typography>
          Big bag : {bb_short.reference}
        </Typography>
      </DialogTitle>,
      <DialogContent key="content">
        <table>
          <tbody>
            {
            Object.keys(bb_short.info_bulle).map((key) => {
              return <tr key={key}>
                <td>{key}</td>
                <td>{bb_short.info_bulle[key]}</td>
              </tr>
            })
            }
          </tbody>
        </table>
      </DialogContent>,
    ]
  }

  render() {
    const { classes, getBigBagDeplacementTitle } = this.props;
    const { openModalCellule, openModalStackNearWall, openModalInfoBulle } = this.state;
    return (
      <div style={{ position: "relative" }}>
        {this.getLoading()}
        <div className={classes.container}>
          {this.displayLegend()}
          {this.getBtnStackNearWall()}
          {getBigBagDeplacementTitle ? getBigBagDeplacementTitle() : null}
          {this.displayZonePlan()}
        </div>
        <Modal
          openModal={openModalCellule}
          onCloseHandler={() => {
            this.setState({
              openModalCellule: false,
              currentCellule: null,
              keyword: "",
            });
            this.props.dispatch({
              type: "ERRORS",
              collection: 'cellule-bbs',
              payload: null,
            })
          }}
          onSubmitHandler={this.onSubmitCelluleHandler.bind(this)}
          maxWidth={"sm"}
          children={this.getModalCellule()}
          additionalButtons={this.getAdditionalButtons()}
        />
        <Modal
          openModal={openModalStackNearWall}
          onCloseHandler={() => {
            this.setState({
              openModalStackNearWall: false,
              selectedLetter: null,
            });
          }}
          onSubmitHandler={this.onSubmitStackNearWallHandler.bind(this)}
          maxWidth={"sm"}
          children={this.getModalStackNearWall()}
        />
        <Modal
          openModal={openModalInfoBulle}
          onCloseHandler={() => {
            this.setState({
              openModalInfoBulle: false,
            });
          }}
          maxWidth={"sm"}
          children={this.getModalInfoBulle()}
        />
      </div>
    );
  }
}

ZonePlan = withStyles(ZonePlanCss)(ZonePlan);

ZonePlan = connect((store) => {
  return {
    celluleStore: store.collections["cellule-bbs"],
  };
})(ZonePlan);

ZonePlan.propTypes = {
  cellules: PropTypes.array.isRequired,
  editCelluleMode: PropTypes.bool,
  big_bag_deplacement: PropTypes.object,
  initDeplacementBigBag: PropTypes.func.isRequired,
  zoneBB_uuid: PropTypes.string.isRequired,
  callbackUpdate: PropTypes.func,
  wallPosition: PropTypes.string,
  legends: PropTypes.array,
};

export default ZonePlan;
