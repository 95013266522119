import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import TextInput from "../../common/Components/TextInput";
import TypeNonConformiteCss from "./css/TypeNonConformiteCss";
import CollectionCrud from "../../common/Components/CollectionCrud";
import CheckboxInput from "../../common/Components/CheckboxInput";

class TypeNonConformite extends Component {
  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression du type de non conformite"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer ce type de non conformite ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(type_non_conformite) {
    let title = !!type_non_conformite
      ? "Modification du type de non conformite : " +
        type_non_conformite.libelle
      : "Ajout d'un nouveau type de non conformite";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  getCreateUpdateModalContent(typenote, typenoteStore, onChangeHandler) {
    let {libelle, is_capeco, is_enabled_front } = typenote;
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextInput
            id="libelle"
            label="Libelle"
            value={libelle}
            margin="normal"
            collectionStore={typenoteStore}
            name="libelle"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <CheckboxInput
            id="is_capeco"
            label="Capeco"
            value={is_capeco}
            margin="normal"
            collectionStore={typenoteStore}
            name="is_capeco"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <CheckboxInput
            id="is_enabled_front"
            label="Affiché modification/création NC"
            value={is_enabled_front}
            margin="normal"
            collectionStore={typenoteStore}
            name='is_enabled_front'
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.container}>
        <CollectionCrud
          collectionName={"type-non-conformites"}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          rights={{
            create: "admin-nc-creation",
            edit: "admin-nc-validation",
            delete: "admin-nc-suppression",
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "libelle",
            sortType: "desc",
            rowsPerPageOptions: [10, 25, 50],
          }}
          defaultValues={{
            is_capeco: false,
            is_enabled_front: true
          }}
          cellsConfig={[
            {
              datakey: "libelle",
              title: "Libelle",
              sortable: true,
              searchable: true,
            },
            {
              datakey: "is_capeco",
              title: "Capeco",
              sortable: true,
              searchable: true,
              format: (obj, key) => {
                return obj[key] ? "Oui" : "Non";
              },
            },
            {
              datakey: "is_enabled_front",
              title: "Affiché",
              sortable: true,
              searchable: true,
              format: (obj,key) => {
                return obj[key] ? 'Oui' : 'Non';
              }
            },
        ]}
        />
      </Grid>
    );
  }
}

TypeNonConformite = withStyles(TypeNonConformiteCss)(TypeNonConformite);

export default TypeNonConformite;
