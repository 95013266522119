import { red } from "@material-ui/core/colors";

export default (theme) => ({
  leadIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
  },
  ico: {
    verticalAlign: "middle",
    marginRight: "0.4em",
  },
  crudTitle: {
    float: "left",
    marginBottom: 0,
    padding: "8px 0",
    color: theme.palette.primary.main,
  },
  button: {
    float: "right",
    padding: 12,
  },
  addButton: {
    marginLeft: "2em",
    marginTop: "0.5em",
    marginRight: "0.5em",
  },
  addButtonIcon: {
    fontSize: "32px",
  },
  returnButton: {
    float: "right",
    padding: "8px 8px 8px 0",
  },
  doneButton: {
    float: "right",
  },
  buttonIcon: {
    marginRight: "0.3em",
  },
  section: {
    marginBottom: "3em",
  },
  sectionTitle: {
    marginBottom: "1em",
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  selectContainer: {
    width: "calc(100% - 1em)",
  },
  totauxDisabled: {
    "& label": {
      color: theme.palette.text.secondary + " !important",
    },
    "& input[disabled]": {
      backgroundColor: "#e7e7e7",
      color: theme.palette.text.primary,
    },
  },
  totauxDisabledWarning: {
    "& label": {
      color: theme.palette.text.secondary + " !important",
    },
    "& input[disabled]": {
      backgroundColor: "#e7e7e7",
      color: theme.palette.error.main, // couleur différente
    },
  },
  emailButton: {
    width: "280px",
  },
  hr: {
    border: "0.5px solid #18b0ff",
    width: "100%",
  },
  commercialContainer: {
    display: "flex",
    flexDirection: "column-reverse",
    paddingLeft: "1rem",
    paddingBottom: "1rem",
  },
  alert:{
    color: '#f90000fa!important',
  },
  rowBgWarning: {
    backgroundColor: "#f7bb132b",
  },
  rowBgDanger: {
    backgroundColor: "#ff030345",
  },
  btn_mail_poids:{
    padding:'0.3rem',
  },
  complement_ref: {
    fontSize: "0.7rem",
    color: "grey",
  },
  center: {
    textAlign: "center",
    width: "100%",
  },
  dateInput: {
    width: "calc(100% - 1em)",
    "& label": {
      transform: "none",
    },
  },
  radioGroup: {
    flexDirection: "row",
  },
  dayLocked: {
    backgroundColor: "#f5002d!important",
    "& button": {
      color: "#fff!important",
    },
  },
  campagneTitle: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  campagne_info:{
    position: "absolute",
    width: '25rem',
    backgroundColor: '#FFFFFF',
    zIndex: 1,
    border: 'solid 1px lightgray',
    padding: '5px',
    borderRadius: '5px',
    right: '15rem',
  },
  type_transport:{
    textAlign: "center",
  },
  pl:{
    margin : "auto",
  },
  notificationsContainer: {
    width: "100%",
    display: "flex",
    marginBottom: "1rem",
    marginTop: "1rem",
    textAlign: "center",
    flexDirection: "column",
  },
  notification:{
    width: "100%",
    padding: "0.2rem",
    marginBottom: "0.5rem",
  },
  red: {
    color: red[500],
  },
  green: {
    color: "#4caf50",
  },
  ncIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  danger:{
    backgroundColor: theme.palette.error.main,
    color: "#fff",
  },
  hoverIcon :{
    "&:hover": {
      cursor: "pointer",
    },
  },
});
