import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Grid, Typography, IconButton, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { GroupWork, CloudDownload, SystemUpdateAlt } from "@material-ui/icons";

import {
  loadCollectionAttribute,
  collectionActions,
} from "../../../reducers/collectionsReducer";
import ArticleSelect from "../../common/Components/ArticleSelect";
import CollectionCrud from "../../common/Components/CollectionCrud";
import NumberInput from "../../common/Components/NumberInput";

import LotCss from "./css/LotCss";
import StockMatierePremiere from "../Stock/MatierePremiere";
import TextInput from "../../common/Components/TextInput";

class Lot extends Component {
  static defaultProps = {
    delete: true,
    pagination: true,
    search: true,
  };

  static propTypes = {
    actionsCallback: PropTypes.func,
    affaireUuid: PropTypes.string,
    createUpdateModalSubmit: PropTypes.string,
    delete: PropTypes.bool,
    modal: PropTypes.object,
    pagination: PropTypes.bool,
    search: PropTypes.bool,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { pirPcrsStore } = nextProps;

    if (!pirPcrsStore.list) {
      return null;
    }
    return {
      default_pir_pcr_uuid: pirPcrsStore.list.find((e) => e.slug === "pcr").uuid,
    };
  }

  state = {
    currentLot: null,
    openStockModal: false,
    default_pir_pcr_uuid: null,
  };

  componentDidMount() {
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "conditionnements",
      this.props.conditionnementsStore
    );
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "familles",
      this.props.famillesStore
    );
    collectionActions(this.props.dispatch, "articles", "INDEX", {
      params: {
        with_trahsed_affaire: true,
      },
    });
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "pir-pcrs",
      this.props.pirPcrsStore
    );
  }

  getDeleteModalTitle() {
    return (
      <div>
        <GroupWork />
        <span>{"Suppression de l'article"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cet article ?"}
      </Typography>
    );
  }

  handleLotModalActions(action, stock) {
    if (action === "close") this.onCloseStockHandler();
    if (action === "create" || action === "update") {
      collectionActions(this.props.dispatch, "affaires", "SHOW", {
        uuid: this.props.affaireUuid,
      });
    }
  }

  getCreateUpdateModalTitle(lot) {
    let title =
      !!lot && !!lot.article
        ? "Modification de l'article : " + lot.article.nom
        : "Ajout d'un nouvel article";
    return (
      <div>
        <CloudDownload />
        <span>{title}</span>
      </div>
    );
  }

  getPirPcrs() {
    if (!this.props.pirPcrsStore.list) {
      return [];
    }
    return this.props.pirPcrsStore.list.map((pir) => {
      return (
        <MenuItem value={pir.uuid} key={pir.uuid}>
          {pir.libelle}
        </MenuItem>
      );
    });
  }

  getCreateUpdateModalContent(lot, lotsStore, onChangeHandler) {
    // Initialise les propriétés qui ne sont pas dans l'objet natif
    for (let prop of ["famille_uuid"]) {
      if (lot.hasOwnProperty("article")) {
        lot[prop] = lot["article"][prop];
      } else if (!lot.hasOwnProperty(prop)) {
        lot[prop] = null;
      }
    }

    // definir le pir_pcr_uuid par defaut si en création
    if (!lot.uuid && !lot.pir_pcr_uuid) {
      lot.pir_pcr_uuid = this.state.default_pir_pcr_uuid;
    }

    const classes = this.props.classes;

    let {
      poids_achat,
      poids_vente,
      prix_unitaire_achat,
      prix_unitaire_vente,
      article,
      conditionnement_uuid,
      pir_pcr_uuid,
    } = lot;
    return (
      <Grid container>
        <Grid item xs={6}>
          <TextInput
            id="conditionnement_uuid"
            label="Conditionnement"
            value={conditionnement_uuid}
            required={true}
            margin="normal"
            collectionStore={lotsStore}
            name="conditionnement_uuid"
            onChangeHandler={onChangeHandler}
            select
            style={{ margin: "0 0 0 5px", width: "calc(100% - 5px)" }}
          >
            {this.getConditionnements()}
          </TextInput>
        </Grid>
        <ArticleSelect
          onChangeHandler={onChangeHandler.bind(this)}
          store={lotsStore}
          famille_uuid={article ? article.famille_uuid : null}
          article_uuid={article ? article.uuid : null}
          familleStyle={{ margin: "0 5px 0 0", width: "calc(100% - 5px)" }}
        />
        <Grid item xs={6}>
          <NumberInput
            id="prix_unitaire_achat"
            label="Prix d'achat unitaire (€/t)"
            value={prix_unitaire_achat}
            margin="normal"
            collectionStore={lotsStore}
            name="prix_unitaire_achat"
            onChangeHandler={onChangeHandler}
            fullWidth
            style={{ margin: "16px 5px 0 0", width: "calc(100% - 5px)" }}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            id="prix_unitaire_vente"
            label="Prix de vente unitaire (€/t)"
            value={prix_unitaire_vente}
            margin="normal"
            collectionStore={lotsStore}
            name="prix_unitaire_vente"
            onChangeHandler={onChangeHandler}
            fullWidth
            style={{ margin: "16px 0 0 5px", width: "calc(100% - 5px)" }}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            id="poids_achat"
            label="Poids d'achat réalisé (t)"
            value={poids_achat}
            margin="normal"
            collectionStore={lotsStore}
            name="poids_achat"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            id="poids_vente"
            label="Poids de vente réalisé (t)"
            value={poids_vente}
            margin="normal"
            collectionStore={lotsStore}
            name="poids_vente"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="pir_pcr_uuid"
            label="PIR/PCR"
            value={pir_pcr_uuid}
            className={classes.selectContainer}
            margin="normal"
            collectionStore={lotsStore}
            name="pir_pcr_uuid"
            onChangeHandler={onChangeHandler}
            select
          >
            {this.getPirPcrs()}
          </TextInput>
        </Grid>
      </Grid>
    );
  }

  getConditionnements() {
    if (!this.props.conditionnementsStore.list) {
      return [];
    }
    return this.props.conditionnementsStore.list.map((conditionnement) => {
      return (
        <MenuItem value={conditionnement.uuid} key={conditionnement.uuid}>
          {conditionnement.nom}
        </MenuItem>
      );
    });
  }

  formatPoids(poids, nbDecimals = 3) {
    if (!poids) {
      return "";
    }
    const inf = new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: nbDecimals,
      maximumFractionDigits: nbDecimals,
    });
    return inf.format(poids);
  }

  formatPrix(prix) {
    if (!prix) {
      return "";
    }
    const inf = new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return inf.format(prix);
  }

  getCellsConfig() {
    let config = [];

    config.push(
      {
        datakey: "article_famille_nom",
        title: "Famille",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "conditionnement_nom",
        title: "Conditionnement",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "pir_pcr_libelle",
        title: "PIR/PCR",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "article_nom",
        title: "Article",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "poids_achat",
        title: "Poids d'achat (t)",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return this.formatPoids(obj[key]);
        },
      },
      {
        datakey: "poids_vente",
        title: "Poids de vente (t)",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return this.formatPoids(obj[key], 2);
        },
      },
      {
        datakey: "prix_unitaire_achat",
        title: "Prix d'achat (€/t)",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return this.formatPrix(obj[key]);
        },
      },
      {
        datakey: "prix_unitaire_vente",
        title: "Prix de vente (€/t)",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return this.formatPrix(obj[key], 2);
        },
      }
    );

    if (this.props.clientCapeco) {
      config.push(
        {
          datakey: "stock_matiere_premiere_code_lot",
          title: "Code lot",
          sortable: true,
          searchable: true,
        },
        {
          datakey: "stock_matiere_premiere_traitement_libelle",
          title: "Traitement",
          sortable: true,
          searchable: true,
        }
      );
    }

    return config;
  }

  getCrudTitle() {
    const { classes } = this.props;

    return (
      <>
        <Typography variant="button" gutterBottom className={classes.crudTitle}>
          Articles
        </Typography>
      </>
    );
  }

  getExtraDataForm() {
    let { affaireUuid } = this.props;
    if (affaireUuid) {
      return {
        affaire_uuid: affaireUuid,
      };
    }
    return {};
  }

  /**
   * Ouverture de la modal de stock
   * @param {*} lot
   */
  openStockModal(lot) {
    this.setState({
      currentLot: lot,
      openStockModal: true,
    });
  }
  /**
   * Fermeture de la modal de stock
   */
  onCloseStockHandler() {
    this.setState({
      currentLot: null,
      openStockModal: false,
    });
  }
  /**
   * Renvoie le btn de stock
   * @param {*} lot
   */
  getStockBtn(lot) {
    if (
      !this.props.affaire ||
      !this.props.affaire.client.is_capeco ||
      !lot.poids_vente
    )
      return null;
    return (
      <IconButton
        aria-label="Ajout au Stock"
        onClick={this.openStockModal.bind(this, lot)}
        key="add_stock"
        className={this.props.classes.stockBtn}
        title={lot.stock_matiere_premiere_uuid ? null : "Ajouter au stock"}
      >
        <SystemUpdateAlt
          style={{
            color: lot.stock_matiere_premiere_uuid !== null ? "#5bd790" : "",
          }}
        />
      </IconButton>
    );
  }
  getModalStock() {
    if (!this.state.currentLot) return null;

    return (
      <StockMatierePremiere
        extradatasForm={{
          lot_uuid: this.state.currentLot.uuid,
          pir_pcr_uuid: this.state.currentLot.pir_pcr_uuid,
        }}
        handleLotModalActions={this.handleLotModalActions.bind(this)}
        modal={{
          only: true,
          open: this.state.openStockModal,
          uuid: this.state.currentLot.stock_matiere_premiere_uuid
            ? this.state.currentLot.stock_matiere_premiere_uuid
            : null,
        }}
        customContext={{ source: "lot" }}
      />
    );
  }

  render() {
    const {
      actionsCallback,
      createUpdateModalSubmit,
      createUpdateModalNext,
      modal,
      datas,
      disable_edit_add,
    } = this.props;

    return (
      <>
        <CollectionCrud
          collectionName={"lots"}
          showBtnEdit={disable_edit_add ? false : true}
          showBtnAdd={disable_edit_add ? false : true}
          rights={{
            create: "admin-cud-affaires",
            edit: "admin-cud-affaires",
            delete: "admin-cud-affaires",
          }}
          datas={datas}
          loadDatas={false}
          showBtnDelete={disable_edit_add ? false : this.props.delete}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent.bind(this)}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          createUpdateModalSubmit={createUpdateModalSubmit}
          createUpdateModalNext={createUpdateModalNext}
          extradatasForm={this.getExtraDataForm()}
          datatableConfig={{
            showPagination: this.props.pagination,
            showSearch: this.props.search,
            defaultSort: "article.nom",
            sortType: "desc",
            nodatalabel: "Aucun article",
          }}
          dataTableExtraNodes={[
            {
              element: this.getCrudTitle(),
              position: "top-left",
            },
          ]}
          additionnalControllers={[this.getStockBtn.bind(this)]}
          cellsConfig={this.getCellsConfig()}
          actionsCallback={actionsCallback}
          modal={modal}
          defaultValues={{
            pir_pcr_uuid: this.state.default_pir_pcr_uuid,
          }}
        />
        {this.getModalStock()}
      </>
    );
  }
}

Lot = withStyles(LotCss)(Lot);

Lot = connect((store) => {
  return {
    articlesStore: store.collections.articles,
    conditionnementsStore: store.collections.conditionnements,
    famillesStore: store.collections.familles,
    affaire: store.collections.affaires.detail,
    pirPcrsStore: store.collections["pir-pcrs"],
  };
})(Lot);

export default Lot;
